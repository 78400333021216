import * as THREE from "three";

class ParticleSystem {
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private particles: THREE.Points;
  private fadeScene: THREE.Scene;
  private fadeMesh: THREE.Mesh;
  private particleCount = 30000;
  private time = 0;
  private fadeStartTime = Date.now();
  private colorPalette: THREE.Color[] = [
    new THREE.Color("#0a2463"), // Deep navy blue
    new THREE.Color("#1e488f"), // Dark royal blue
    new THREE.Color("#2a6ebb"), // Medium steel blue
    new THREE.Color("#235789"), // Dark cerulean
    new THREE.Color("#1b3b6f"), // Dark blue
  ];

  constructor() {
    // Scene setup
    this.scene = new THREE.Scene();

    // Camera setup
    this.camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      200
    );
    this.camera.position.z = 70;

    // Renderer setup
    this.renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector("#bg") as HTMLCanvasElement,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.autoClear = false;

    // Setup fade effect
    this.fadeScene = new THREE.Scene();
    const fadeGeometry = new THREE.PlaneGeometry(2, 2);
    const fadeMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 1.0, // Start fully black
    });
    this.fadeMesh = new THREE.Mesh(fadeGeometry, fadeMaterial);
    this.fadeMesh.scale.set(1000, 1000, 1000);
    this.fadeScene.add(this.fadeMesh);

    // Create particles
    this.particles = this.createParticles();
    this.scene.add(this.particles);

    // Event listeners
    window.addEventListener("resize", this.onWindowResize.bind(this));

    // Start animation
    this.animate();
  }

  private createGradientTexture(): THREE.Texture {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    const size = 32;
    canvas.width = size;
    canvas.height = size;

    // Create radial gradient
    const gradient = ctx.createRadialGradient(
      size / 2,
      size / 2,
      0,
      size / 2,
      size / 2,
      size / 2
    );

    gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
    gradient.addColorStop(0.2, "rgba(255, 255, 255, 0.9)");
    gradient.addColorStop(0.5, "rgba(255, 255, 255, 0.5)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    // Fill with gradient
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, size, size);

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    return texture;
  }

  private createParticles(): THREE.Points {
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(this.particleCount * 3);
    const initialPositions = new Float32Array(this.particleCount * 3);
    const colors = new Float32Array(this.particleCount * 3);
    const phases = new Float32Array(this.particleCount * 3);

    for (let i = 0; i < this.particleCount; i++) {
      const i3 = i * 3;
      // Position - increased spread
      const x = (Math.random() - 0.5) * 200;
      const y = (Math.random() - 0.5) * 100;
      const z = (Math.random() - 0.5) * 40;

      positions[i3] = x;
      positions[i3 + 1] = y;
      positions[i3 + 2] = z;

      // Store initial positions for wave motion
      initialPositions[i3] = x;
      initialPositions[i3 + 1] = y;
      initialPositions[i3 + 2] = z;

      // Random phase offsets for more varied motion
      phases[i3] = Math.random() * Math.PI * 2;
      phases[i3 + 1] = Math.random() * Math.PI * 2;
      phases[i3 + 2] = Math.random() * Math.PI * 2;

      // Color with higher intensity
      const color =
        this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)];
      colors[i3] = color.r;
      colors[i3 + 1] = color.g;
      colors[i3 + 2] = color.b;
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute(
      "initialPosition",
      new THREE.BufferAttribute(initialPositions, 3)
    );
    geometry.setAttribute("phase", new THREE.BufferAttribute(phases, 3));
    geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

    const material = new THREE.PointsMaterial({
      size: 0.6,
      sizeAttenuation: true,
      vertexColors: true,
      transparent: true,
      opacity: 0.7,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
      map: this.createGradientTexture(),
    });

    return new THREE.Points(geometry, material);
  }

  private updateParticles() {
    this.time += 0.015;

    const positions = this.particles.geometry.attributes.position
      .array as Float32Array;
    const initialPositions = this.particles.geometry.attributes.initialPosition
      .array as Float32Array;
    const phases = this.particles.geometry.attributes.phase
      .array as Float32Array;

    for (let i = 0; i < positions.length; i += 3) {
      const frequency = 2.0;
      const amplitude = 3.0;

      // Create more complex wave patterns with multiple frequencies
      const xOffset =
        Math.sin(initialPositions[i] * 0.05 + this.time + phases[i]) *
          amplitude +
        Math.cos(initialPositions[i] * 0.02 - this.time * 1.5) *
          amplitude *
          0.5;

      const yOffset =
        Math.cos(initialPositions[i + 1] * 0.05 + this.time + phases[i + 1]) *
          amplitude +
        Math.sin(initialPositions[i + 1] * 0.02 - this.time * 1.1) *
          amplitude *
          0.5;

      const zOffset =
        Math.sin(
          (initialPositions[i + 2] + initialPositions[i]) * 0.05 +
            this.time +
            phases[i + 2]
        ) *
          amplitude +
        Math.cos(initialPositions[i + 2] * 0.02 - this.time * 1.7) *
          amplitude *
          4.3;

      positions[i] =
        initialPositions[i] +
        xOffset * Math.sin(frequency * this.time + initialPositions[i] * 0.1) +
        Math.sin(this.time * 2 + phases[i]) * 0.5;

      positions[i + 1] =
        initialPositions[i + 1] +
        yOffset *
          Math.cos(frequency * this.time + initialPositions[i + 1] * 0.1) +
        Math.cos(this.time * 2.5 + phases[i + 1]) * 0.5;

      positions[i + 2] =
        initialPositions[i + 2] +
        zOffset *
          Math.sin(frequency * this.time + initialPositions[i + 2] * 0.1) +
        Math.sin(this.time * 3 + phases[i + 2]) * 0.5;
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
  }

  private onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private updateFadeIn() {
    const elapsed = (Date.now() - this.fadeStartTime) / 1000; // Convert to seconds
    const fadeInDuration = 3.0; // 3 seconds fade in

    if (elapsed < fadeInDuration) {
      const opacity = 1.1 - elapsed / fadeInDuration;
      (this.fadeMesh.material as THREE.MeshBasicMaterial).opacity = opacity;
    } else {
      (this.fadeMesh.material as THREE.MeshBasicMaterial).opacity = 0.1;
    }
  }

  private animate() {
    requestAnimationFrame(this.animate.bind(this));

    // Update fade-in effect
    this.updateFadeIn();

    // Apply fade effect
    this.renderer.render(this.fadeScene, this.camera);

    // Rotate the entire particle system very slowly
    this.particles.rotation.x = Math.sin(Date.now() * 0.001) * 0.3;
    this.particles.rotation.y = Math.sin(Date.now() * 0.0012) * 0.3;

    this.updateParticles();
    this.renderer.render(this.scene, this.camera);
  }
}

export default ParticleSystem;
