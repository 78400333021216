import ParticleSystem1 from "./main";
import ParticleSystem2 from "./main2";
import ParticleSystem3 from "./main3";

// Initialize when DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  // Use timestamp modulo 3 to select the system
  const systems = [ParticleSystem1, ParticleSystem2, ParticleSystem3];
  const systemIndex = Date.now() % 3;
  const SystemClass = systems[systemIndex];

  new SystemClass();
});
