import * as THREE from "three";

class ParticleSystem {
  private scene: THREE.Scene = new THREE.Scene();
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private particles: THREE.Points;
  private fadeScene: THREE.Scene = new THREE.Scene();
  private fadeMesh: THREE.Mesh;
  private particleCount = 1000; // Reduced count for metaballs
  private time = 0;
  private fadeStartTime = Date.now();
  private colorPalette: THREE.Color[] = [
    new THREE.Color("#0a2463"),
    new THREE.Color("#1e488f"),
    new THREE.Color("#2a6ebb"),
    new THREE.Color("#235789"),
    new THREE.Color("#1b3b6f"),
  ];

  private vertexShader = `
    uniform float time;
    attribute float size;
    varying vec3 vColor;
    varying vec2 vUv;
    void main() {
      vUv = uv;
      vColor = color;
      vec3 pos = position;
      
      // Add some wave motion
      float wave = sin(time * 0.5 + position.x * 0.02) * cos(time * 0.5 + position.z * 0.02) * 2.0;
      pos.y += wave;
      
      vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);
      gl_Position = projectionMatrix * mvPosition;
      gl_PointSize = size * (300.0 / -mvPosition.z);
    }
  `;

  private fragmentShader = `
    uniform float time;
    uniform sampler2D pointTexture;
    varying vec3 vColor;
    varying vec2 vUv;

    void main() {
      vec2 uv = gl_PointCoord;
      
      // Create soft circle
      float r = length(uv - vec2(0.5));
      float a = 1.0 - smoothstep(0.0, 0.5, r);
      
      // Add some internal variation
      float noise = sin(uv.x * 10.0 + time) * cos(uv.y * 10.0 + time) * 0.1;
      a *= 1.0 + noise;
      
      gl_FragColor = vec4(vColor, a * 0.4);  // Reduced alpha for better blending
    }
  `;

  constructor() {
    try {
      // Camera setup
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        200
      );
      this.camera.position.z = 50;

      // Scene setup
      this.scene.background = new THREE.Color(0x000000);

      // Renderer setup
      const canvas = document.querySelector("#bg") as HTMLCanvasElement;
      if (!canvas) {
        throw new Error("Canvas element not found");
      }

      this.renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: true,
        preserveDrawingBuffer: true,
        alpha: true,
      });
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.autoClear = false;

      // Setup fade effect
      const fadeGeometry = new THREE.PlaneGeometry(2, 2);
      const fadeMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 1.0,
      });
      this.fadeMesh = new THREE.Mesh(fadeGeometry, fadeMaterial);
      this.fadeMesh.scale.set(1000, 1000, 1000);
      this.fadeScene.add(this.fadeMesh);

      // Add lights for ambient illumination
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      this.scene.add(ambientLight);

      // Create particles
      this.particles = this.createParticles();
      this.scene.add(this.particles);

      // Event listeners
      window.addEventListener("resize", this.onWindowResize.bind(this));

      // Start animation
      this.animate();
    } catch (error) {
      console.error("Error initializing ParticleSystem:", error);
      throw error;
    }
  }

  private createParticles(): THREE.Points {
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(this.particleCount * 3);
    const colors = new Float32Array(this.particleCount * 3);
    const sizes = new Float32Array(this.particleCount);

    for (let i = 0; i < this.particleCount; i++) {
      const i3 = i * 3;

      // Position in a more concentrated area for better metaball effect
      const radius = 10 + Math.random() * 30;
      const angle = Math.random() * Math.PI * 2;
      const height = (Math.random() - 0.5) * 20;

      positions[i3] = Math.cos(angle) * radius;
      positions[i3 + 1] = height;
      positions[i3 + 2] = Math.sin(angle) * radius;

      // Random color from palette
      const color =
        this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)];
      colors[i3] = color.r;
      colors[i3 + 1] = color.g;
      colors[i3 + 2] = color.b;

      // Larger sizes for better metaball effect
      sizes[i] = 15 + Math.random() * 15;
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    geometry.setAttribute("size", new THREE.BufferAttribute(sizes, 1));

    const material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        pointTexture: { value: new THREE.TextureLoader().load("disc.png") },
      },
      vertexShader: this.vertexShader,
      fragmentShader: this.fragmentShader,
      transparent: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
      vertexColors: true,
    });

    return new THREE.Points(geometry, material);
  }

  private updateParticles() {
    this.time += 0.01;

    // Update shader time uniform
    (this.particles.material as THREE.ShaderMaterial).uniforms.time.value =
      this.time;

    // Get positions for dynamic updates
    const positions = this.particles.geometry.attributes.position
      .array as Float32Array;

    for (let i = 0; i < this.particleCount; i++) {
      const i3 = i * 3;

      // Add subtle circular motion
      const radius = Math.sqrt(
        positions[i3] * positions[i3] + positions[i3 + 2] * positions[i3 + 2]
      );
      const angle = Math.atan2(positions[i3 + 2], positions[i3]);

      // Slower orbital movement
      const newAngle = angle + 0.001 * (30 / radius);

      positions[i3] = Math.cos(newAngle) * radius;
      positions[i3 + 2] = Math.sin(newAngle) * radius;

      // Add vertical wobble
      positions[i3 + 1] += Math.sin(this.time + i * 0.1) * 0.05;
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
  }

  private onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private updateFadeIn() {
    const elapsed = (Date.now() - this.fadeStartTime) / 1000;
    const fadeInDuration = 3.0;

    if (elapsed < fadeInDuration) {
      const opacity = 1.1 - elapsed / fadeInDuration;
      (this.fadeMesh.material as THREE.MeshBasicMaterial).opacity = opacity;
    } else {
      (this.fadeMesh.material as THREE.MeshBasicMaterial).opacity = 0.1;
    }
  }

  private animate() {
    try {
      requestAnimationFrame(this.animate.bind(this));

      // Update fade-in effect
      this.updateFadeIn();

      // Apply fade effect
      this.renderer.render(this.fadeScene, this.camera);

      // Update particle positions and rotations
      this.updateParticles();

      // Render the scene
      this.renderer.render(this.scene, this.camera);
    } catch (error) {
      console.error("Error in animation loop:", error);
    }
  }
}

export default ParticleSystem;
