import * as THREE from "three";

class ParticleSystem {
  private scene: THREE.Scene = new THREE.Scene();
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private particles: THREE.InstancedMesh;
  private spotlights: THREE.SpotLight[] = [];
  private fadeScene: THREE.Scene = new THREE.Scene();
  private floor: THREE.Mesh = new THREE.Mesh();
  private time = 0;
  private gridSize = 20; // Reduced grid size for bigger spacing
  private spacing = 4; // Increased spacing for bigger cube
  private cubeSize: number;

  constructor() {
    try {
      this.cubeSize = this.gridSize * this.spacing;

      // Camera setup
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        2000 // Increased far plane for infinite floor
      );
      this.camera.position.set(60, 50, 60); // Moved camera back for larger cube
      this.camera.lookAt(0, 0, 0);

      // Scene setup
      this.scene.background = new THREE.Color(0x000000);
      this.scene.fog = new THREE.Fog(0x000000, 200, 1000); // Add fog for infinite effect

      // Renderer setup with shadows
      const canvas = document.querySelector("#bg") as HTMLCanvasElement;
      if (!canvas) {
        throw new Error("Canvas element not found");
      }

      this.renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: true,
        preserveDrawingBuffer: true,
        alpha: true,
      });
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.autoClear = false;
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      // Add floor
      this.setupFloor();

      // Add ambient and point light
      this.setupStaticLights();

      // Create particles
      this.particles = this.createParticles();
      this.scene.add(this.particles);

      // Add spotlights
      this.setupSpotlights();

      // Event listeners
      window.addEventListener("resize", this.onWindowResize.bind(this));

      // Start animation
      this.animate();
    } catch (error) {
      console.error("Error initializing ParticleSystem:", error);
      throw error;
    }
  }

  private setupFloor() {
    // Create a repeating grid texture
    const textureSize = 1024;
    const canvas = document.createElement("canvas");
    canvas.width = textureSize;
    canvas.height = textureSize;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.fillStyle = "#111111";
      ctx.fillRect(0, 0, textureSize, textureSize);

      // Draw grid lines
      ctx.strokeStyle = "#222222";
      ctx.lineWidth = 2;
      const gridSpacing = textureSize / 20;

      // Draw vertical lines
      for (let x = 0; x <= textureSize; x += gridSpacing) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, textureSize);
        ctx.stroke();
      }

      // Draw horizontal lines
      for (let y = 0; y <= textureSize; y += gridSpacing) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(textureSize, y);
        ctx.stroke();
      }
    }

    const texture = new THREE.CanvasTexture(canvas);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(100, 100); // Repeat the texture many times

    const floorGeometry = new THREE.PlaneGeometry(2000, 2000, 100, 100);
    const floorMaterial = new THREE.MeshStandardMaterial({
      map: texture,
      metalness: 0.5,
      roughness: 0.8,
      transparent: false,
    });

    this.floor = new THREE.Mesh(floorGeometry, floorMaterial);
    this.floor.rotation.x = -Math.PI / 2;
    this.floor.position.y = -this.cubeSize * 1;
    this.floor.receiveShadow = true;

    this.scene.add(this.floor);
  }

  private setupStaticLights() {
    // Add ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
    this.scene.add(ambientLight);

    // Add point light
    const pointLight = new THREE.PointLight(0xffffff, 100000);
    pointLight.position.set(200, this.cubeSize * 2, 100);
    pointLight.castShadow = true;
    pointLight.shadow.mapSize.width = 2048;
    pointLight.shadow.mapSize.height = 2048;
    pointLight.shadow.camera.near = 0.5;
    pointLight.shadow.camera.far = 2000; // Increased for infinite floor
    this.scene.add(pointLight);

    // Add subtle rim light
    const rimLight = new THREE.PointLight(0x4466ff, 50000);
    rimLight.position.set(-200, this.cubeSize, -100);
    this.scene.add(rimLight);
  }

  private createParticles(): THREE.InstancedMesh {
    // Create a smoother particle shape - icosahedron with 2 subdivisions
    const geometry = new THREE.IcosahedronGeometry(0.5, 2);
    const material = new THREE.MeshStandardMaterial({
      color: 0x4488ff,
      metalness: 0.6,
      roughness: 0.1, // Reduced roughness for smoother look
      emissive: 0x112244,
      emissiveIntensity: 0.7,
    });

    const totalParticles = this.gridSize * this.gridSize * this.gridSize;
    const instancedMesh = new THREE.InstancedMesh(
      geometry,
      material,
      totalParticles
    );
    instancedMesh.castShadow = true;
    instancedMesh.receiveShadow = true;

    let index = 0;
    const matrix = new THREE.Matrix4();
    const halfSize = this.cubeSize / 2;
    const rotationMatrix = new THREE.Matrix4();
    const scaleMatrix = new THREE.Matrix4();

    // Position particles in a grid
    for (let x = 0; x < this.gridSize; x++) {
      for (let y = 0; y < this.gridSize; y++) {
        for (let z = 0; z < this.gridSize; z++) {
          const xPos = x * this.spacing - halfSize + this.spacing / 2;
          const yPos = y * this.spacing - halfSize + this.spacing / 2;
          const zPos = z * this.spacing - halfSize + this.spacing / 2;

          // Add random rotation to each particle
          rotationMatrix.makeRotationFromEuler(
            new THREE.Euler(
              Math.random() * Math.PI,
              Math.random() * Math.PI,
              Math.random() * Math.PI
            )
          );

          // Initial scale
          scaleMatrix.makeScale(1, 1, 1);

          matrix
            .makeTranslation(xPos, yPos, zPos)
            .multiply(rotationMatrix)
            .multiply(scaleMatrix);

          instancedMesh.setMatrixAt(index, matrix);
          index++;
        }
      }
    }

    return instancedMesh;
  }

  private setupSpotlights() {
    // Create four spotlights with different colors
    const colors = [0x4466ff, 0xff4466, 0x44ff66, 0xff8844];
    const angles = [0, Math.PI / 2, Math.PI, Math.PI * 1.5]; // Starting angles for each light

    angles.forEach((angle, index) => {
      const radius = this.cubeSize * 10; // Increased distance
      const height = this.cubeSize * 2.5; // Height above the cube
      const x = Math.cos(angle) * radius;
      const z = Math.sin(angle) * radius;

      const spotlight = new THREE.SpotLight(colors[index], 5000);
      spotlight.position.set(x, height, z);
      spotlight.angle = Math.PI / 15; // Broader angle
      spotlight.penumbra = 0.3; // Softer edges
      spotlight.decay = 1;
      spotlight.distance = this.cubeSize * 10;

      // Enable shadows for spotlights
      spotlight.castShadow = true;
      spotlight.shadow.mapSize.width = 1024;
      spotlight.shadow.mapSize.height = 1024;
      spotlight.shadow.camera.near = 0.5;
      spotlight.shadow.camera.far = 500;

      // Create a target object that will always be at the center
      const target = new THREE.Object3D();
      target.position.set(0, 0, 0);
      this.scene.add(target);
      spotlight.target = target;

      this.scene.add(spotlight);
      this.spotlights.push(spotlight);
    });
  }

  private updateParticles() {
    this.time = Date.now() / 1000;
    const matrix = new THREE.Matrix4();
    const halfSize = this.cubeSize / 2;
    let index = 0;
    const scaleMatrix = new THREE.Matrix4();

    // Update particle positions with wave motion
    for (let x = 0; x < this.gridSize; x++) {
      for (let y = 0; y < this.gridSize; y++) {
        for (let z = 0; z < this.gridSize; z++) {
          const xBase = x * this.spacing - halfSize + this.spacing / 2;
          const yBase = y * this.spacing - halfSize + this.spacing / 2;
          const zBase = z * this.spacing - halfSize + this.spacing / 2;

          // Calculate wave values for position and scale
          const waveX = Math.sin(this.time + y * 0.3 + x * 0.1);
          const waveY = Math.cos(this.time + x * 0.2 + z * 0.2 + y * 0.1);
          const waveZ = Math.sin(this.time + z * 0.3 + x * 0.2);

          // Position offsets
          const xOffset = waveX * 5.2;
          const yOffset = waveY * 5.2;
          const zOffset = waveZ * 10.2;

          // Calculate scale based on waves
          const scaleBase = 1.0;
          const scaleWave =
            (Math.sin(this.time * 2 + (x * 0.6 + y * 0.2 + z) * 0.2) + 1) * 0.5; // Value between 0 and 1
          const scale = scaleBase + scaleWave * 5.5; // Scale between 1.0 and 2.5

          // Apply scale
          scaleMatrix.makeScale(scale, scale, scale);

          matrix
            .makeTranslation(xBase + xOffset, yBase + yOffset, zBase + zOffset)
            .multiply(scaleMatrix);

          this.particles.setMatrixAt(index, matrix);
          index++;
        }
      }
    }

    this.particles.instanceMatrix.needsUpdate = true;

    // Update spotlight positions with slower rotation
    this.spotlights.forEach((spotlight, index) => {
      const angle = this.time * 0.2 + index * Math.PI; // Even slower rotation
      const radius = this.cubeSize * 4;
      const height = this.cubeSize * 1.5;
      const x = Math.cos(angle) * radius;
      const z = Math.sin(angle) * radius;

      spotlight.position.set(x, height, z);
      spotlight.lookAt(0, 0, 0);
    });
  }

  private onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  private animate() {
    try {
      requestAnimationFrame(this.animate.bind(this));

      // Apply fade effect
      this.renderer.render(this.fadeScene, this.camera);

      // Update particle positions and rotations
      this.updateParticles();

      // Slowly rotate the entire scene
      this.scene.rotation.y += 0.003;

      // Update floor texture repeat based on camera position
      const floorMaterial = this.floor.material as THREE.MeshStandardMaterial;
      if (floorMaterial.map) {
        const cameraDistance = this.camera.position.length();
        floorMaterial.map.repeat.set(
          100 * (cameraDistance / 500),
          100 * (cameraDistance / 500)
        );
      }

      // Render the scene
      this.renderer.render(this.scene, this.camera);
    } catch (error) {
      console.error("Error in animation loop:", error);
    }
  }
}

export default ParticleSystem;
